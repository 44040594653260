<template>
  <button
    :disabled="disabled"
    :class="{
      'default-button-event-highlighted': highlighted,
      'px-3_4': small,
      'vplus-style': vplus,
    }"
    class="components-essentials-defaultbuttonevent-container"
    @click="$emit('click', $event)"
  >
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    highlighted: {
      default: false,
      type: Boolean,
    },
    vplus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.components-essentials-defaultbuttonevent-container {
  @apply border-theme-primary text-theme-primary font-theme-title text-lg bg-transparent border-2 border-solid h-12 tracking-title uppercase px-6;
}

.default-button-event-highlighted {
  @apply border-theme-secondary text-theme-secondary;
}

button:disabled {
  border-color: #bdbdbd;
  color: #bdbdbd;
}

.px-3_4 {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.vplus-style {
  @apply border-white text-white;
  background: #e3051b;
}

.vplus-style:hover {
  @apply bg-white;
  color: #e3051b;
  border-color: #e3051b;
}
</style>
